import React, {
  SetStateAction,
  useContext, 
  useRef,
  useState,
} from 'react';
import AppContext from '../../../../context/app.context';
import { StoreStoreDto } from '../dto';
import { StoreStoreService } from '../store-store.service';
import {
  BootstrapInstanceEnum
} from '../../../modal/hooks/bootstrap-instance.enum';
import { useStoreState } from '../../../../hooks/stores/use-store-state';
import {
  useParentUntilFrom
} from '../../../modal/hooks/use-parent-until-from.hook';
import { UIModel } from '../../../ui.model';
import PupSub from 'pubsub-js';
import { PubSubEnum } from '../../../../enums/pub-sub.enum';

export interface IStoreStoreLiveEditorUIModel extends UIModel<any>{
  name: string;
  setName: React.Dispatch<SetStateAction<string>>;
  slug: string;
  setSlug: React.Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<SetStateAction<string>>;
  addressLine1: string;
  setAddressLine1: React.Dispatch<SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: React.Dispatch<SetStateAction<string>>;
  postalCode: string;
  setPostalCode: React.Dispatch<SetStateAction<string>>;
  enabled: boolean;
  setEnabled: React.Dispatch<SetStateAction<boolean>>;
  isLive: boolean;
  setIsLive: React.Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

const useCreateUIModel = (): IStoreStoreLiveEditorUIModel => {
  const appContext = useContext(AppContext);
  const changeStoresState = useStoreState();
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [uuid, setUUID] = useState('');
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [data, setData] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);

  const empty = () => {
    setUUID('');
    setName('');
    setSlug('');
    setEmail('');
    setCity('');
    setState('');
    setAddressLine1('');
    setAddressLine2('');
    setPostalCode('');
    setEnabled(false);
    setIsLive(false);
    setData([]);
    setIsLoading(false);
  }
  
  const fill = (model: StoreStoreDto) => {
    if (!model) {
      return empty();
    }
    setUUID(model.uuid || '');
    setName(model.name || '');
    setSlug(model.slug || '');
    setEmail(model.email || '');
    setCity(model.headOfficeAddressCity || '');
    setState(`US-${model.headOfficeAddressState || ''}`);
    setAddressLine1(model.headOfficeAddressLine1 || '');
    setAddressLine2(model.headOfficeAddressLine2 || '');
    setPostalCode(model.headOfficeAddressPostalCode || '');
    setEnabled(model.enabled);
    setIsLive(model.isLive);
    setData([]);
  }

  const save = () => {
    const storeTransitory = {
      uuid,
      name,
      slug,
      email,
      headOfficeAddressCity: city,
      headOfficeAddressState: state,
      headOfficeAddressLine1: addressLine1,
      headOfficeAddressLine2: addressLine2,
      headOfficeAddressPostalCode: postalCode,
      enabled,
      isLive,
    } as StoreStoreDto;
    setIsLoading(true);
    StoreStoreService.save(storeTransitory).
      then((storeSaved: StoreStoreDto) => {
        changeStoresState(storeSaved);

        const $modal = parentUntilFrom(thisRef, 'modal',
          BootstrapInstanceEnum.MODAL);
        $modal.hide();
        
        PupSub.publish(PubSubEnum.LISTING_CONTAINER_SEARCH);
      }).
      finally(() => {
        setIsLoading(false);
      });
  };
  
  return {
    thisRef,
    uuid,
    setUUID,
    name,
    setName,
    slug,
    setSlug,
    email,
    setEmail,
    city,
    setCity,
    state,
    setState,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    postalCode,
    setPostalCode,
    enabled,
    setEnabled,
    isLive,
    setIsLive,
    isLoading,
    setIsLoading,
    empty,
    data,
    setData,
    fill,
    save
  };
};

export { useCreateUIModel };