import { CacheUtil } from './cache';
import PupSub from 'pubsub-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { Pipes } from './pipes';
import { RequestApiEnum } from '../enums/request-api.enum';
import { PubSubEnum } from '../enums/pub-sub.enum';
import { IUIToast } from '../components/toast/toast.ui';

const okStatusCodes = [200, 201];

export interface IRequestConfig extends AxiosRequestConfig {
  notify?: boolean;
}

/**
 * Helper Function which verifies if the __jsession stored key exists and then
 * put the jwt token into options.headers
 * @param options
 * @returns options with authorization header if jwt exists
 */
const injectToken = (options: IRequestConfig): IRequestConfig => {
  delete options.notify;
  const jwt = CacheUtil.cache('__jsession');
  if (!jwt) {
    return options;
  }
  options.headers = options.headers || {};
  options.headers['Authorization'] = 'Bearer ' + jwt;

  return options;
};

const axiosData = async <T>(
  promise: Promise<AxiosResponse>,
  Class: ClassConstructor<T>,
  interceptor?: (result: json) => any,
) => {
  const result = await promise;
  if (!result || !okStatusCodes.includes(result.status)) {
    throw new Error(`${result.status}: ${result.statusText}`);
  }
  const responseIntercepted = typeof interceptor === 'function' ? interceptor(
    result.data) : result.data;
  return Pipes.transform(Class, responseIntercepted) as T;
};

/**
 * Class with request utils.
 * This is a wrapper for axios module
 */
export class RequestUtil {
  /**
   * Wrapper function for axios.get. This function inject the jwt if exists
   * @param url
   * @param options
   * @param type
   * @param interceptor
   * @returns {Promise<*>}
   * @throws Error
   */
  static async get<T>(
    url: string,
    options: IRequestConfig | undefined = undefined,
    type: () => ClassConstructor<T>,
    interceptor?: (result: json) => any,
  ): Promise<T> {
    return axiosData(
      axios.get(
        `${RequestApiEnum.BASE_API_PATH}${url}`,
        injectToken({...options} || {})),
      type(),
      interceptor,
    ).then(response => {
      if (options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_SAVED,
          {message: 'Saved!'} as IUIToast);
      }
      return response;
    }).catch(error => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_ERROR,
          {
            message: error?.response?.data?.message ?? 'There was an error!',
          } as IUIToast);
      }
      throw error;
    });
  }

  /**
   * Wrapper function for axios.post. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @param interceptor
   * @returns {Promise<*>}
   * @throws Error
   */
  static async post<T>(
    url: string,
    params: json,
    options: IRequestConfig | undefined = undefined,
    type: () => ClassConstructor<T>,
    interceptor?: (result: json) => any,
  ) {
    return axiosData(
      axios.post(
        `${RequestApiEnum.BASE_API_PATH}${url}`,
        params,
        injectToken({...options} || {}),
      ),
      type(),
      interceptor,
    ).then(response => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_SAVED,
          {message: 'Saved!'} as IUIToast);
      }
      return response;
    }).catch(error => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_ERROR,
          {
            message: error?.response?.data?.message ?? 'There was an error!',
          } as IUIToast);
      }
      throw error;
    });
  }

  /**
   * Wrapper function for axios.put. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @param interceptor
   * @returns {Promise<*>}
   * @throws Error
   */
  static async put<T>(
    url: string,
    params: json,
    options: IRequestConfig | undefined = undefined,
    type: () => ClassConstructor<T>,
    interceptor?: (result: json) => any,
  ) {
    return axiosData(
      axios.put(
        `${RequestApiEnum.BASE_API_PATH}${url}`,
        params,
        injectToken({...options} || {}),
      ),
      type(),
      interceptor,
    ).then(response => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_SAVED,
          {message: 'Saved!'} as IUIToast);
      }
      return response;
    }).catch(error => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_ERROR,
          {
            message: error?.response?.data?.message ?? 'There was an error!',
          } as IUIToast);
      }
      throw error;
    });
  }

  /**
   * Wrapper function for axios.patch. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @param interceptor
   * @returns {Promise<*>}
   * @throws Error
   */
  static async patch<T>(
    url: string,
    params: json,
    options: IRequestConfig | undefined = undefined,
    type: () => ClassConstructor<T>,
    interceptor?: (result: json) => any,
  ) {
    return axiosData(
      axios.patch(
        `${RequestApiEnum.BASE_API_PATH}${url}`,
        params,
        injectToken({...options} || {}),
      ),
      type(),
      interceptor,
    ).then(response => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_SAVED,
          {message: 'Saved!'} as IUIToast);
      }
      return response;
    }).catch(error => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_ERROR,
          {
            message: error?.response?.data?.message ?? 'There was an error!',
          } as IUIToast);
      }
      throw error;
    });
  }

  /**
   * Wrapper function for axios.delete. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @param interceptor
   * @returns {Promise<*>}
   * @throws Error
   */
  static async delete<T>(
    url: string,
    options: IRequestConfig | undefined = undefined,
    type: () => ClassConstructor<T>,
    interceptor?: (result: json) => any,
  ): Promise<any> {
    return axiosData(
      axios.delete(
        `${RequestApiEnum.BASE_API_PATH}${url}`,
        injectToken({...options} || {}),
      ),
      type(),
      interceptor,
    ).then(response => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_SAVED,
          {message: 'Deleted!'} as IUIToast);
      }
      return response;
    }).catch(error => {
      if (typeof options?.notify !== 'boolean' || options?.notify) {
        PupSub.publish(PubSubEnum.TOAST_ERROR,
          {
            message: error?.response?.data?.message ?? 'There was an error!',
          } as IUIToast);
      }
      throw error;
    });
  }
}
